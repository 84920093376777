import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#000000',
      main: '#000000',
      dark: '#000000'
    },
    secondary: {
      light: '#1E6C79',
      main: '#1E6C79',
      dark: '#1E6C79'
    },
    error: {
      light: '#FA5858',
      main: '#FA5858',
      dark: '#FA5858',
    },
  },
  overrides: {
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#CEE3F6',
      },
      barColorPrimary: {
        backgroundColor: '#0174DF',
      },
    },
  },
});