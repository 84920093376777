import { BASE_URL } from '../constants';
import WellnubService from './wellnubService';

class ProfileService extends WellnubService {
  get = () => this.axios.get(`${BASE_URL}/v1/users/profile`);

  update = payload => this.axios.put(`${BASE_URL}/v1/users/profile`, payload);
}

export default ProfileService;
