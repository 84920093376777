/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React, { Fragment } from "react";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { Tab, Tabs } from "@material-ui/core";

import { get } from "lodash";
import NotFoundPage from "containers/NotFoundPage/Loadable";

import _NewWorkout from "containers/NewWorkout/Loadable";
import {
  MainPage,
  ContentSection,
  FooterSection,
} from "components/LayoutSections";

import {
  userIsAuthenticatedRedir,
  userIsNotAuthenticatedRedir,
  userIsAuthenticated,
} from "utils/auth";
// import GlobalStyle from '../../global-styles';

import _Login from "containers/SignIn/Loadable";
import _Register from "containers/Register/Loadable";

import _Header from "components/Header";
import Footer from "components/Footer";

import _MyPrograms from "containers/MyPrograms/Loadable";
import _NewProgram from "containers/NewProgram/Loadable";
import _Dashboard from "containers/Dashboard/Loadable";
import _ExercisesHome from "containers/ExercisesHome/Loadable";
import _VideoWorkOut from "containers/VideoWorkOut/Loadable";
import _CreatedProgram from "containers/CreatedProgram/Loadable";

import _EditProfile from "containers/EditProfile/Loadable";
import _ProfilePlan from "containers/ProfilePlan/Loadable";
import _ProfileBankInformation from "containers/ProfileBankInformation/Loadable";

import TermsAndConditions from "containers/Legal/TermsAndConditions";
import PrivacyPolicy from "containers/Legal/PrivacyPolicy";

import ZoomSession from "containers/ZoomSession/Loadable";
import { VideoWorkOut } from "@wellnub/web-common";

const Login = userIsNotAuthenticatedRedir(_Login);
const Register = userIsNotAuthenticatedRedir(_Register);

const Header = withRouter(userIsAuthenticated(_Header));
const NewWorkout = userIsAuthenticatedRedir(_NewWorkout);

//Home components
const Dashboard = userIsAuthenticatedRedir(_Dashboard);
const ExercisesHome = userIsAuthenticatedRedir(_ExercisesHome);

//Pogram components
const MyPrograms = userIsAuthenticatedRedir(_MyPrograms);
const NewProgram = userIsAuthenticatedRedir(_NewProgram);
//const VideoWorkOut = withRouter(userIsAuthenticatedRedir(_VideoWorkOut));
const CreatedProgram = withRouter(userIsAuthenticatedRedir(_CreatedProgram));

//Settings containers
const EditProfile = userIsAuthenticatedRedir(_EditProfile);
const ProfilePlan = userIsAuthenticatedRedir(_ProfilePlan);
const ProfileBankInformation = userIsAuthenticatedRedir(
  _ProfileBankInformation
);

const tabValues = {
  "/profile/myProfile": "myProfile",
  "/profile/plan": "plan",
  "/profile/bank": "bank",
};

const getTabValue = (location) => {
  const tabValue = get(tabValues, location, "other");
  return tabValue;
};

const App = () => (
  <div>
    <Switch>
      <Route exact path="/terms" component={TermsAndConditions} />
      <Route exact path="/privacy" component={PrivacyPolicy} />
      <Route exact path="/videoWorkOut/:id" component={VideoWorkOut} />
      <Redirect from="/" to="/login" exact />
      {/* <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/terms" component={TermsAndConditions} /> */}
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/zoomSession" component={ZoomSession} />
      <Route>
        <MainPage>
          <ContentSection>
            <Header />
            <Switch>
              <Route exact path="/myPrograms" component={MyPrograms} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/exercises" component={ExercisesHome} />
              <Route exact path="/program/new" component={NewProgram} />
              <Route
                exact
                path="/workout/:mode/:programId/:session"
                component={NewWorkout}
              />
              <Route
                exact
                path="/workout/:mode/:programId/:vWoDId/:session"
                component={NewWorkout}
              />
              <Route
                path="/profile"
                render={({ location }) => (
                  <Fragment>
                    <Tabs
                      value={getTabValue(location.pathname)}
                      variant="fullWidth"
                      indicatorColor="primary"
                    >
                      <Tab
                        label="Mi Perfil"
                        value="myProfile"
                        component={Link}
                        to="/profile/myProfile"
                      />
                      {/* <Tab
                        label="Mi plan Wellnub"
                        value="plan"
                        component={Link}
                        to="/profile/plan"
                      /> */}
                      {/* <Tab
                        label="Mis ingresos"
                        value="bank"
                        component={Link}
                        to="/profile/bank"
                      /> */}
                    </Tabs>
                    <Switch>
                      <Route
                        exact
                        path="/profile/myProfile"
                        component={EditProfile}
                      />
                      <Route
                        exact
                        path="/profile/plan"
                        component={ProfilePlan}
                      />
                      <Route
                        path="/profile/bank"
                        component={ProfileBankInformation}
                      />
                    </Switch>
                  </Fragment>
                )}
              />
              <Route
                path="/program/:id/:activeTab"
                component={CreatedProgram}
              />
            </Switch>
          </ContentSection>
          <FooterSection>
            <Footer />
          </FooterSection>
        </MainPage>
      </Route>
      <Route component={NotFoundPage} />
    </Switch>
  </div>
);

export default App;
