/*
 *
 * SignInNutritionist constants
 *
 */

export const USER_LOGGING_IN = '[USER] USER_LOGGING_IN';
export const USER_LOGGED_IN = '[USER] USER_LOGGED_IN';
export const USER_LOGGED_OUT = '[USER] USER_LOGGED_OUT';
export const SET_USER = '[USER] SET_USER';
