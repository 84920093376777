/*
 *
 * SignInNutritionist actions
 *
 */

import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_LOGGING_IN,
  SET_USER,
} from './constants';
// import userApi from '../../utils/api/user.api';
import { handleApiError } from '../../components/SnackBarAlert/actions';
import wellnubApi from '../../utils/network/wellnub.api';
import axios from 'axios';
import { push } from "connected-react-router";


// TODO: Add toasts to tell the user the request status.
export const loginUser = payload => dispatch => {
  dispatch(userIsLoggingIn(true));
  wellnubApi.auth
    .login(payload)
    .then(user => {
      localStorage.setItem('wellnub-user', JSON.stringify(user.data));
      localStorage.setItem('accessToken', user.data.token.accessToken);
      axios.defaults.headers.common = { Authorization: `Bearer ${user.data.token.accessToken}` };
      dispatch(userLoggedIn(user.data));
      if (!user.data.user.hasLoggedIn) {
        dispatch(push("/profile/myProfile"));
      }
    })
    .catch(error => {
      handleApiError(dispatch, error);
    })
    .finally(dispatch(userIsLoggingIn(false)));
};

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const setUser = user => ({
  type: SET_USER,
  payload: user,
});

export const userIsLoggingIn = isLogging => ({
  type: USER_LOGGING_IN,
  payload: isLogging,
});

export const userLoggedIn = user => ({
  type: USER_LOGGED_IN,
  payload: user,
});
