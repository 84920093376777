import axios from 'axios';
import ExerciseService from './exercise.service';
import AuthService from './auth.service';
import ProgramService from './program.service';
import ProfileService from './profile.service';
import SessionService from './session.service';
import VWoDService from './vWoD.service';
import UserService from './user.service';
import SuscriptionService from './suscription.service';
import FileService from './file.service';
import ZoomService from './zoom.service';

class WellnubApi {
  constructor() {
    const accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };

    this.exercise = new ExerciseService(axios);

    this.auth = new AuthService(axios);

    this.program = new ProgramService(axios);

    this.profile = new ProfileService(axios);

    this.session = new SessionService(axios);

    this.vWoD = new VWoDService(axios);

    this.user = new UserService(axios);

    this.suscription = new SuscriptionService(axios);

    this.file = new FileService(axios);

    this.zoom = new ZoomService(axios);
  }

  // updateToken = accessToken => {
  //   axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
  // }
}

export default new WellnubApi();
