import { BASE_URL } from '../constants';
import WellnubService from './wellnubService';

class UserService extends WellnubService {
  addFavorite = videoExerciseId => this.axios.post(`${BASE_URL}/v1/users/favorite`, { favoriteId: videoExerciseId });

  deleteFavorite = videoExerciseId => this.axios.delete(`${BASE_URL}/v1/users/favorite/${videoExerciseId}`);
}

export default UserService;
