import React from 'react';
import { useController, useWatch } from 'react-hook-form';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  group: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
}));

const WellnubCheckboxGroupInput = ({
  name,
  row,
  rules,
  options,
  control,
  fullWidth,
  helperText,
  defaultValue,
}) => {
  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    rules,
    control,
    defaultValue,
  });

  const values = useWatch({ control, name });

  const classes = useStyles();

  const onChangeHandler = (value, checked) => {
    if (checked) return [...values, value];
    else return values.filter(item => item !== value);
  };

  return (
    <FormControl fullWidth={fullWidth} error={!!error} {...inputProps}>
      <FormGroup row={row} className={classes.group}>
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            label={option.label}
            control={
              <Checkbox
                color="primary"
                defaultChecked={option?.checked ?? false}
                onChange={(_, checked) =>
                  onChange(onChangeHandler(option.value, checked))
                }
              />
            }
          />
        ))}
      </FormGroup>
      <FormHelperText>{error?.message ?? helperText}</FormHelperText>
    </FormControl>
  );
};

export default WellnubCheckboxGroupInput;
