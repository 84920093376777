import { BASE_URL } from "../constants";
import WellnubService from "./wellnubService";

class AuthService extends WellnubService {
  login = (payload) =>
    this.axios.post(`${BASE_URL}/v1/auth/login?role=coach`, payload);

  register = (payload) =>
    this.axios.post(`${BASE_URL}/v1/auth/register`, payload);

  getTempPassword = (email) =>
    this.axios.post(`${BASE_URL}/v1/auth/tempPassword`, { email });
}

export default AuthService;
