import { BASE_URL } from '../constants';
import WellnubService from './wellnubService';

class VWoDService extends WellnubService {
  createWeek = programId =>
    this.axios.post(`${BASE_URL}/v1/weekVideoSession`, { programId });

  deleteWeek = id => this.axios.delete(`${BASE_URL}/v1/weekVideoSession/${id}`);

  updateDay = (dayId, payLoad) =>
    this.axios.put(`${BASE_URL}/v1/dayVideoSession/${dayId}`, payLoad);
}

export default VWoDService;
