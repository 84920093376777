import { BASE_URL } from '../constants';
import WellnubService from './wellnubService';

class CustomClientService extends WellnubService {
  create = payload => this.axios.post(`${BASE_URL}/v1/suscription`, payload);

  get = id => this.axios.get(`${BASE_URL}/v1/suscription/${id}`);

  list = () => this.axios.get(`${BASE_URL}/v1/suscription`);

  update = (id, payload) =>
    this.axios.patch(`${BASE_URL}/v1/suscription/${id}`, payload);

  delete = id => this.axios.delete(`${BASE_URL}/v1/suscription/${id}`);
}

export default CustomClientService;
