import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'dark'
        ? theme.palette.grey[800]
        : theme.palette.grey[200],
  },
}));

const Copyright = () => (
  <div style={{textAlign: 'center'}}>
    <Link href="/privacy" target="_blank">
      Aviso de Privacidad
    </Link>
    &nbsp; | &nbsp;
    <Link href="/terms"  target="_blank">
      Terminos y Condiciones
    </Link>
    &nbsp; | &nbsp;
    <Link href="#" onClick={e => e.preventDefault()}>
      Wellnub.com
    </Link>
    <Typography variant="body2" display="inline">
      &nbsp; Todos los derechos reservados.
    </Typography>
  </div>
);

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="md">
        <Copyright />
      </Container>
    </footer>
  );
};

export default Footer;
