import React from 'react';
import Loading from 'react-loading-components';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  title: {
    position: 'relative',
    top: '25%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.secondary.light,
    fontWeight: 500,
    fontSize: '1.2em',
    height: 0,
  },
  quote: {
    marginTop: 20,
    fontWeight: 200,
    fontSize: '2.5em',
    color: '#555',
  },
});

const LoadingComponent = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.title}>
      <p className={classes.quote}>¡Loggin to Wellnub!</p>
      <Loading type="tail_spin" width={100} height={100} fill="#F40000" />
    </div>
  </div>
);

LoadingComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoadingComponent);
