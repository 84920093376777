import {
  ERROR_MESSAGE,
  WARNING_MESSAGE,
  INFORMATION_MESSAGE,
  SUCCESS_MESSAGE,
  HIDE_MESSAGE,
} from './constants';

export const handleApiError = async (
  dispatch,
  error,
  defaultMessage = undefined,
) => {
  try {
    const errorJson = error.response.data;
    if (errorJson.isPublic === true) {
      dispatch(showWarningMessage(errorJson.message));
      return;
    }
    if (defaultMessage !== undefined) {
      dispatch(showWarningMessage(defaultMessage));
    }
  } catch (err) {
    dispatch(
      showErrorMessage(
        'Could not connect with the server check your connection and try again',
      ),
    );
  }
};

export const showErrorMessage = message => ({
  type: ERROR_MESSAGE,
  payload: message,
});

export const showWarningMessage = message => ({
  type: WARNING_MESSAGE,
  payload: message,
});

export const showInformationMessage = message => ({
  type: INFORMATION_MESSAGE,
  payload: message,
});

export const showSuccessMessage = message => ({
  type: SUCCESS_MESSAGE,
  payload: message,
});

export const hideMessage = () => ({
  type: HIDE_MESSAGE,
});
