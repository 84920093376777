import { BASE_URL } from '../constants';
import WellnubService from './wellnubService';

class ProgramService extends WellnubService {
  create = payload => this.axios.post(`${BASE_URL}/v1/program`, payload);

  get = id => this.axios.get(`${BASE_URL}/v1/program/${id}`);

  list = () => this.axios.get(`${BASE_URL}/v1/program`);

  update = (id, payload) => this.axios.patch(`${BASE_URL}/v1/program/${id}`, payload);

  delete = id => this.axios.post(`${BASE_URL}/v1/program/${id}`);

  getHome = () => this.axios.get(`${BASE_URL}/v1/program/home`);
}

export default ProgramService;
