export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
export const DAEMON = "@@saga-injector/daemon";
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";
// TODO: Use env variable to store base URL
// export const BASE_URL = 'http://52.53.195.172:3000';
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const WELLNUB_COLORS = {
  circuit: "#F49D05",
  exercise: "#D92C02",
  rest: "#81B1C1",
  lightGray: "#575454",
  wn_blue: "#377990",
  wn_black: "#000606",
  wn_white: "#FFFFFF",
  btn_gray: "#959596",
};
