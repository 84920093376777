import React from 'react';

import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  row: {
    '&:nth-child(odd)': {
      backgroundColor: ({ variant }) =>
        variant === 'primary' ? 'none' : '#e3e3e3',
    },
    '&:nth-child(even)': {
      backgroundColor: ({ variant }) =>
        variant === 'primary' ? 'none' : '#a9a9a9',
    },
  },
  label: {
    color: '#464b51',
  },
}));

const WellnubTableRow = ({
  row: { data, id },
  actions,
  disabled,
  variant = 'primary',
  actionPosition,
}) => {
  const rowKeys = Object.keys(data);

  const classes = useStyles({ variant });

  return (
    <TableRow className={classes.row}>
      {actions && actionPosition === 'start' && (
        <TableCell align="center">
          {actions.map(({ title, type, handler }) => (
            <Tooltip key={title} title={title}>
              <IconButton onClick={() => handler(id)} disabled={disabled}>
                {type === 'DELETE' && <DeleteIcon fontSize="small" />}
                {type === 'CANCEL' && <CancelIcon fontSize="small" />}
              </IconButton>
            </Tooltip>
          ))}
        </TableCell>
      )}
      {rowKeys.map(key => (
        <TableCell align="center" key={`${key}-${data[key]}`}>
          <Typography className={classes.label} variant="body2">
            {data[key]}
          </Typography>
        </TableCell>
      ))}
      {actions && actionPosition === 'end' && (
        <TableCell align="center">
          {actions.map(({ title, type, handler }) => (
            <Tooltip key={title} title={title}>
              <IconButton onClick={() => handler(id)} disabled={disabled}>
                {type === 'DELETE' && <DeleteIcon fontSize="small" />}
                {type === 'FILE' && <AttachFileIcon fontSize="small" />}
                {type === 'CANCEL' && <CancelIcon fontSize="small" />}
              </IconButton>
            </Tooltip>
          ))}
        </TableCell>
      )}
    </TableRow>
  );
};

export default WellnubTableRow;
