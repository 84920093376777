import styled from 'styled-components';

const FOOTER_HEIGHT = 2.5;

export const MainPage = styled.div`
  position: relative;
  min-height: 100vh;
`;

export const ContentSection = styled.div`
  padding-bottom: ${FOOTER_HEIGHT}rem;
`;

export const FooterSection = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: ${FOOTER_HEIGHT}rem;
`;
