import { BASE_URL } from '../constants';
import WellnubService from './wellnubService';

class SessionService extends WellnubService {
  create = payload => this.axios.post(`${BASE_URL}/v1/liveSession`, payload);

  get = id => this.axios.get(`${BASE_URL}/v1/liveSession/${id}`);

  list = () => this.axios.get(`${BASE_URL}/v1/liveSession`);

  update = (id, payload) =>
    this.axios.patch(`${BASE_URL}/v1/liveSession${id}`, payload);

  delete = id => this.axios.post(`${BASE_URL}/v1/liveSession/${id}`);
}

export default SessionService;
