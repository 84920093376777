import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import EventNoteIcon from '@material-ui/icons/EventNote';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HomeIcon from '@material-ui/icons/Home';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get } from 'lodash';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import { userLoggedOut } from '../containers/SignIn/actions';
import navLogo from '../images/navbar-logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.black
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  toolbarNav: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  toolbarLogo: {
    display: 'block',
    height: '25px'
  }
}));

const ButtonAppBar = props => {
  useEffect(() => {}, []);

  const classes = useStyles();

  const [state, setState] = React.useState({
    isMenuOpened: false,
  });

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, isMenuOpened: open });
  };

  const handleLogout = () => {
    localStorage.removeItem('wellnub-user');
    localStorage.removeItem('accessToken');
    props.logout();
  };

  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <Link to="/dashboard" className={classes.link}>
          <ListItem button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </Link>

        <Link to="/myPrograms" className={classes.link}>
          <ListItem button>
            <ListItemIcon>
              <EventNoteIcon />
            </ListItemIcon>
            <ListItemText primary="Programas" />
          </ListItem>
        </Link>

        <Link to="/exercises" className={classes.link}>
          <ListItem button>
            <ListItemIcon>
              <FitnessCenterIcon />
            </ListItemIcon>
            <ListItemText primary="Ejercicios" />
          </ListItem>
        </Link>

        <Link to="/profile/myProfile" className={classes.link}>
          <ListItem button>
            <ListItemIcon>
              <AccountCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Configuración" />
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {get(props, 'user.data.user.role', '') !== 'inbody' && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div className={classes.toolbarNav}>
            <Link to="/">
              <img src={navLogo} alt="Wellnub Brand" className={classes.toolbarLogo}/>
            </Link>
            <Button color="inherit" onClick={() => handleLogout()}>
              Salir
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer open={state.isMenuOpened} onClose={toggleDrawer(false)}>
        {sideList()}
      </Drawer>
    </div>
  );
};

ButtonAppBar.propTypes = {
  // logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

function mapDispatchToProps(dispatch) {
  return {
    logout: () => {
      dispatch(userLoggedOut());
    },
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(ButtonAppBar);
