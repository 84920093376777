import { BASE_URL } from '../constants';
import WellnubService from './wellnubService';

class AuthService extends WellnubService {
  uploadProfileImage = (file, userId) => {
    var formData = new FormData();
    formData.append("profileImage", file);
    return this.axios.post(`${BASE_URL}/v1/file/profile/${userId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  } 
}

export default AuthService;
