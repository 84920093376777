/*
 *
 * SignInNutritionist reducer
 *
 */
import produce from 'immer';
import {
  USER_LOGGED_IN,
  USER_LOGGING_IN,
  USER_LOGGED_OUT,
  SET_USER,
} from './constants';

export const initialState = {
  data: JSON.parse(localStorage.getItem('wellnub-user')),
  isLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const signInNutritionistReducer = (state = initialState, action) =>
  produce(state, (/* draft */) => {
    switch (action.type) {
      case USER_LOGGED_OUT:
        return {
          data: null,
          isLoading: false,
        };

      case USER_LOGGING_IN:
        return {
          ...state,
          isLoading: action.payload,
        };

      case USER_LOGGED_IN:
        return {
          ...state,
          data: action.payload,
        };

      case SET_USER:
        return {
          ...state,
          data: action.payload,
        };

      default:
        return state;
    }
  });

export default signInNutritionistReducer;
