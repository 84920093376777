import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { SnackbarProvider } from 'notistack';

import { ThemeProvider, Slide, CssBaseline } from '@material-ui/core';

// import { ModalSelector, AlertSelector } from 'components/organisms';

import history from 'utils/history';

// import LanguageProvider from 'containers/LanguageProvider';

import App from './App';

import configureStore from './configureStore';

import { theme } from 'styles/Theme';
// import { store } from 'redux/store';
const store = configureStore({}, history);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <SnackbarProvider
            preventDuplicate
            maxSnack={4}
            TransitionComponent={Slide}
          >
            <App />
            {/*<AlertSelector />*/}
            {/*<ModalSelector />*/}
            <CssBaseline />
          </SnackbarProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
